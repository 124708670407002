.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  transition: 0.3s;
  z-index: 200;
  pointer-events: none;
  opacity: 0;
  &.show {
    transition-delay: 0.2s;
    opacity: 1;
    pointer-events: all;
  }
  &.transparent {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
