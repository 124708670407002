.error {

  &__top {
    font-weight: 600;
    font-size: 64px;
    line-height: 44px;
    color: #b9c2c8;
  }
  &__desc {
    margin-top: 20px;
    font-size: 22px;
    line-height: 28px;
    color: #b9c2c8;
  }
  &__wrapper {
    height: 100vh;
    width: 280px;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__button {
    margin-top: 45px;
    width: 100%;
  }
  &__number {
    margin: 4px 0 12px;
  }
}
