.footer{
  height: 50px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1); 

  &__wrapper{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 1420px;
    padding: 0 32px;
    margin: 0 auto;   
  }
  &__copyright{
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
  }
  // &__socials{
  //     margin-left: auto;
  // }
}