.input-wrapper {
  position: relative;
  width: 100%;
}
.input {
  height: 56px;
  width: 100%;
  padding: 16px;
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  border: none;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #000000;
  &::placeholder {
    color: #8e8e93;
  }
  &.error {
    background-image: url('../../../images/icons/error.svg');
    background-position: 95% 50%;
    background-repeat: no-repeat;
  }
  &.withLabel {
    border: 1px solid #aeaeb2;
    box-sizing: border-box;
    border-radius: 4px;
    &.error {
      background-color: transparent;
    }
  }
}

.input-label {
  position: absolute;
  top: -7px;
  left: 12px;
  padding: 0 5px;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.24px;
  color: #8e8e93;
  background-color: #ffffff;
}

.error-label {
  position: absolute;
  bottom: -25px;
  left: 0;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.24px;
  font-weight: normal;
  color: #e8004d;
}
